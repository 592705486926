export default {
  navigation: {
    title: "L'Entrepôt",
    "search-bar": {
      placeholder: "Que cherchez-vous ?",
    },
    support: "Assistance",
    primary: {
      categories: "Catégories",
      manufacturers: "Marques",
      "article-groups": "Groupes d'articles",
    },
    secondary: {
      products: "Produits",
      manufacturers: "Marques",
      suppliers: "Fournisseurs",
    },
  },
  manufacturers: {
    title: "Nos marques",
    description:
      "Retrouvez les marques innovantes et de confiance du secteur photovoltaïque qui optent pour la vente en ligne pour réduire vos prix.",
  },
  suppliers: {
    title: "Nos fournisseurs",
    description:
      "Les fournisseurs se rendent disponibles pour vous proposer des solutions innovantes avec des prix uniquement disponibles en ligne.",
  },
  support: {
    title: "Parler à un humain",
    description:
      "Contactez les fournisseurs et fabricants pour plus d'assistance concernant une commande, une livraison ou un remboursement.",
  },

  about: {
    "reservable-listings": {
      title: "Achat groupé",
      description:
        "Participez à nos achats groupés pour obtenir le prix d’une expédition en conteneur avec une petite quantité !",
      button: "En savoir plus sur les achats groupés",
    },
    collections: {
      title: "Parcourir le catalogue par équipement",
      action: "Parcourir le catalogue complet",
    },
    "purchasing-methods": {
      heading: "Comment acheter ?",
      title: "Achetez votre matériel comme vous le souhaitez",
      description:
        "Vous en avez assez de vous procurer du matériel à l'ancienne ? Voici votre boutique du solaire tout-en-un qui vous offre la possibilité d'acheter du matériel à des petits prix pour vous faire gagner du temps.",
      checkout: {
        title: "Achat direct",
        description:
          "Vous ne voulez plus passer du temps à vous renseigner auprès des commerciaux ? Trouvez les produits répertoriés auprès des fournisseurs et distributeurs disponibles pour un achat immédiat.",
      },
      rfq: {
        title: "Demande de prix",
        description:
          "Habitué à vous renseigner pour une meilleure offre auprès de vos fournisseurs ? Envoyez une demande de devis pour vérifier la disponibilité dans votre quantité et voir si vous pouvez obtenir un meilleur prix.",
      },
      reservation: {
        title: "Achat groupé",
        description:
          "Réservez votre quantité lors des programmes d'achats groupés pour obtenir des prix avantageux au conteneur.",
      },
    },
    values: {
      perks: {
        "more-choice": {
          title: "Plusieurs choix disponibles",
          description:
            "Comparez plusieurs fournisseurs pour trouver le meilleur prix",
        },
        "fast-delivery": {
          title: "Livraison rapide",
          description:
            "Les fournisseurs expédieront dès qu’ils auront reçu votre commande par notre plateforme",
        },
        "regular-offers": {
          title: "Offres régulières",
          description:
            "Des offres uniques sur le matériel offert toute l’année dans la boutique comme nulle part ailleurs !",
        },
      },
    },
  },
}
